import * as RadioGroup from '@radix-ui/react-radio-group';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	Form,
	FormButtons,
	FormHeader,
	Input,
	Select,
	Textarea,
	useZodForm,
} from '../../../../../components/FormElements';
import { PageHeader } from '../../../../../components/PageHeader';
import { toastMessage } from '../../../../../helpers/toastMessage';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { appState, useAppState } from '../../../../../store/appState';
import { VehicleCategory } from '../../../OperationPages/Settings/Categories/VehicleCategory';
import { VehiclesSchema, VehiclesSchemaType } from '../schemas/VehiclesSchema';
import { oldIcons, VehicleIcon } from '../../../../../components/Icon/vehicles';
import { twMerge } from 'tailwind-merge';
import { Controller } from 'react-hook-form';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { carMakers } from '../../../../../store/carsMakers';
import { Vehicle } from '../../../../../services/angular/types/Order';
import { z } from 'zod';
import Button from '../../../../../components/Button/Button';
import { Loading } from '../../../../../components/Loading';
import { CompaniesTable } from '../../../../../components/Angular/CompaniesTable';
import { Modal } from '../../../../../components/Modal';
import { useState } from 'react';
import { ImagePicker } from '../../../../../components/ImagePicker';

const entityStatus = [
	{
		value: 1,
		label: 'Aprovação pendente',
	},
	{
		value: 2,
		label: 'Veículo não aprovado',
	},
	{
		value: 3,
		label: 'Veículo aprovado',
	},
];

const statusSchema = z.object({
	status: z.string(),
});

type StatusFormType = z.infer<typeof statusSchema>;

const AddVehicle = () => {
	const role = useUserState((state) => state.userRole);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { id } = useParams();
	const [openModal, setOpenModal] = useState(false);
	const [, setSubCategoryError] = useState<string>();
	const company = useUserState((state) => state.company);
	const geofence = useAppState((state) => state.geofence);

	const handleGeofence = (geofenceId: number) => {
		const value = geofence?.find((g) => {
			return g.id === geofenceId ? g?.name : '';
		});
		return value?.name;
	};

	const form = useZodForm({
		schema: VehiclesSchema,
	});

	const statusForm = useZodForm({
		schema: statusSchema,
	});

	const {
		data: vehicleToEdit,
		isFetching,
		isLoading,
	} = useQuery(
		['edit-vehicle', id],
		async () =>
			role?.id === Number(appState.userRoles.user.value)
				? (await api.get<Vehicle>(`/companies/${company!.id}/vehicles/${id!}`))
						.data
				: (await api.get<Vehicle>(`/vehicles/${id!}`)).data,
		{
			enabled: !!id && id !== 'novo',
			cacheTime: 0,
			staleTime: 0,
			onSuccess: (data) => {
				form.reset({
					...data,
					geofenceId: {
						value: data?.category?.geofenceId,
						label: handleGeofence(Number(data?.category?.geofenceId)),
					},
					make: data?.make
						? {
								value: data?.make,
								label: data?.make,
						  }
						: undefined,
					year: data?.year || undefined,
					featureOneActive: data?.featureOneActive,
					featureTwoActive: data?.featureTwoActive,
					activeFeature: data?.featureOneActive ? 'featureOne' : 'featureTwo',
					observation: data?.observation || '',
				});

				statusForm.reset({
					status: String(data?.status),
				});
			},
		}
	);

	const { data, refetch } = useQuery(
		['vehicle-category', form.watch('geofenceId')?.value],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						geofenceId: form.watch('geofenceId')?.value, //
					},
				}
			)
	);

	const { data: frontImage } = useQuery(
		['front-image', vehicleToEdit?.front],
		async () => {
			return await api.get('assets', {
				params: {
					imageName: vehicleToEdit?.front,
				},
			});
		},
		{
			enabled: !!vehicleToEdit?.front,
		}
	);
	const { data: leftImage } = useQuery(
		['left-image', vehicleToEdit?.left],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: vehicleToEdit?.left,
				},
			});
		},
		{
			enabled: !!vehicleToEdit?.left,
		}
	);
	const { data: crvImage } = useQuery(
		['crv-image', vehicleToEdit?.crv],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: vehicleToEdit?.crv,
				},
			});
		},
		{
			enabled: !!vehicleToEdit?.crv,
		}
	);
	const { data: rightImage } = useQuery(
		['right-image', vehicleToEdit?.right],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: vehicleToEdit?.right,
				},
			});
		},
		{
			enabled: !!vehicleToEdit?.right,
		}
	);
	const { data: rearImage } = useQuery(
		['rear-image', vehicleToEdit?.rear],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: vehicleToEdit?.rear,
				},
			});
		},
		{
			enabled: !!vehicleToEdit?.rear,
		}
	);

	const addNewVehicle = async (data: VehiclesSchemaType) => {
		try {
			if (vehicleToEdit) {
				role?.id === Number(appState.userRoles.user.value)
					? await api.put(
							`companies/${vehicleToEdit?.companyId}/vehicles/${vehicleToEdit.id}`,
							{
								...data,
							}
					  )
					: await api.put(`/vehicles/${vehicleToEdit.id}`, {
							...data,
					  });
				toast.success(toastMessage.success.updated);
			} else if (company) {
				await api.post(`companies/${company?.id}/vehicles`, {
					...data,
				});
				toast.success(toastMessage.success.created);
			}

			void queryClient.refetchQueries({
				queryKey: ['vehicles'],
				exact: false,
			});

			role?.id === Number(appState.userRoles.user.value) &&
				navigate('/negocios/veiculos');
		} catch (error: any) {
			if (typeof error?.response?.data?.error === 'string') {
				toast.error(error.response?.data.error);
				if (
					error.response?.data.error ===
					'O veículo deve ter uma sub-categoria selecionada.'
				) {
					setSubCategoryError(
						'O veículo deve ter uma sub-categoria selecionada.'
					);
				}
			} else {
				vehicleToEdit
					? toast.error(toastMessage.error.updated)
					: toast.error(toastMessage.error.created);
			}
		}
	};

	const handleAccessControlStatus = async ({ status }: StatusFormType) => {
		try {
			await api.put(`vehicles/${id!}/status`, {
				status: Number(status),
			});
			toast.success('Status alterado com sucesso!');
			statusForm.reset({
				status,
			});
		} catch (err) {
			toast.error('Algo deu errado.');
		}
	};

	const uploadAsset = async (
		file: File,
		type: 'front' | 'left' | 'right' | 'rear' | 'crv'
	) => {
		try {
			const formData = new FormData();
			formData.append('image', file);

			const { data } = await api.post('/assets', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			form.setValue(type, data.imageName);
		} catch (error) {
			toast.error('Erro ao fazer upload da imagem.');
		}
	};

	if (isFetching && isLoading) return <Loading title="Carregando veículo..." />;

	return (
		<>
			<PageHeader
				title={id ? 'Edição de Veículo' : 'Cadastro de veículos'}
				description="Informações do veículo que vai atender pedidos de entregas"
			/>
			{vehicleToEdit?.status ===
				Number(appState.approvalStatus.Aprovado.value) &&
				role?.id === Number(appState.userRoles.user.value) && (
					<div className="mb-4 border-l-4 border-blue/50 bg-blue/20 px-4 py-4 md:px-8 md:py-8">
						Veículos aprovados pela equipe Bee Bee não podem ser alterados.
						Entre em contato com a Bee Bee para obter suporte.
					</div>
				)}
			<div
				className={twMerge(
					'flex w-full flex-col gap-4 lg:flex-row',
					vehicleToEdit?.status ===
						Number(appState.approvalStatus.Aprovado.value) &&
						role?.id === Number(appState.userRoles.user.value) &&
						'pointer-events-none'
				)}
			>
				<WhiteContainer
					divProps={{
						className: 'w-full',
					}}
					className="mb-4 flex flex-col gap-4"
				>
					<div className="mb-4 flex flex-col gap-4 lg:flex-row">
						<div className="flex w-full flex-col gap-4">
							<div className="flex flex-col gap-4 lg:flex-row">
								<div className="mb-4 flex w-full flex-col gap-4">
									<FormHeader title="Informações do modelo" />
									<div className="flex flex-col items-center gap-4 md:flex-row">
										<Select
											label="Marca"
											controller={{
												control: form.control,
												name: 'make',
											}}
											options={carMakers}
										/>
										<Input
											type="number"
											label="Ano"
											maxLength={4}
											{...form.register('year', {
												onChange: (e) => {
													e.target.value = e.target.value.slice(0, 4);
												},
											})}
										/>
										<Input label="Modelo" {...form.register('model')} />
									</div>
								</div>
								<div className="mb-4 flex w-full flex-col gap-4">
									<FormHeader title="Dados de licenciamento" />
									<div className="flex w-full flex-col items-center gap-4 md:flex-row">
										<Input
											label="Placa*"
											maxLength={7}
											{...form.register('plate')}
											errorMessage={form.formState.errors.plate?.message}
										/>
										<Select
											label="Região"
											errorMessage={form.formState.errors.geofenceId?.message}
											controller={{
												control: form.control,
												name: 'geofenceId',
											}}
											options={geofence?.map((state) => ({
												label: state?.name,
												value: state?.id,
											}))}
											onChange={() => {
												void refetch();
											}}
										/>
										<Input
											type="number"
											label="Renavam"
											{...form.register('renavam', {
												onChange: (e) => {
													e.target.value = e.target.value.slice(0, 11);
												},
											})}
										/>
									</div>
								</div>
							</div>
							<div className="flex flex-col gap-6 md:flex-row md:justify-between">
								<div className="w-full lg:max-h-[160px] lg:w-48 ">
									<FormHeader title="Categoria*" className="mb-5" />
									<div
										className={twMerge(
											'flex h-[103px] cursor-pointer flex-col items-center justify-center rounded font-bold ring-1 ring-neutral-500 hover:opacity-90 hover:shadow-md 2xl:h-[151px]',
											form.formState.errors.categoryId?.message && 'ring-red'
										)}
										onClick={() => setOpenModal(true)}
									>
										{!form.watch('categoryId') ? (
											<>
												<div>Selecione uma categoria</div>
												<VehicleIcon name="bikerBag" />
											</>
										) : (
											<>
												<div className="text-center">
													{
														data?.data?.find(
															(category: any) =>
																category.id === form.getValues('categoryId')
														)?.name
													}
												</div>
												<VehicleIcon
													name={
														// @ts-expect-error
														oldIcons[
															data?.data?.find(
																(category: any) =>
																	category.id === form.getValues('categoryId')
															)?.icon
														]
													}
												/>
												<span>
													{form.watch('activeFeature') === 'featureOne' &&
														data?.data?.find(
															(category: any) =>
																category.id === form.getValues('categoryId')
														)?.typeOneDescription}

													{form.watch('activeFeature') === 'featureTwo' &&
														data?.data?.find(
															(category: any) =>
																category.id === form.getValues('categoryId')
														)?.typeTwoDescription}
												</span>
											</>
										)}
									</div>
									{/* <h1 className="text-sm text-red">{subCategoryError}</h1>
									<h1 className="text-sm text-red">
										{form.formState.errors.categoryId?.message}
									</h1> */}
								</div>
								<div className="flex flex-wrap gap-4 ">
									<div className="flex flex-col gap-4 ">
										<FormHeader title="Fotos do veículo" />
										<div className="flex w-full flex-wrap gap-4 lg:flex-row">
											<ImagePicker
												className="h-28 w-28 2xl:h-40 2xl:w-40"
												bottomLabel="Frente*"
												isError={!!form.formState.errors.front?.message}
												onImagePick={(event) => {
													const image = event.target.files?.item(0);
													if (!image) return;
													void uploadAsset(image, 'front');
												}}
												src={frontImage?.data.url}
											/>
											<ImagePicker
												className="h-28 w-28 2xl:h-40 2xl:w-40"
												bottomLabel="Esquerda*"
												isError={!!form.formState.errors.left?.message}
												onImagePick={(event) => {
													const image = event.target.files?.item(0);
													if (!image) return;
													void uploadAsset(image, 'left');
												}}
												src={leftImage?.data.url}
											/>
											<ImagePicker
												className="h-28 w-28 2xl:h-40 2xl:w-40"
												bottomLabel="Direita"
												onImagePick={(event) => {
													const image = event.target.files?.item(0);
													if (!image) return;
													void uploadAsset(image, 'right');
												}}
												src={rightImage?.data.url}
											/>
											<ImagePicker
												className="h-28 w-28 2xl:h-40 2xl:w-40"
												bottomLabel="Traseira"
												onImagePick={(event) => {
													const image = event.target.files?.item(0);
													if (!image) return;
													void uploadAsset(image, 'rear');
												}}
												src={rearImage?.data.url}
											/>
										</div>
									</div>
									<div className="flex flex-col gap-4">
										<FormHeader title="Documentação" />
										<ImagePicker
											className="h-28 w-28 2xl:h-40 2xl:w-40"
											isError={!!form.formState.errors.crv?.message}
											bottomLabel="CRLV*"
											onImagePick={(event) => {
												const image = event.target.files?.item(0);
												if (!image) return;
												void uploadAsset(image, 'crv');
											}}
											src={crvImage?.data.url}
										/>
									</div>
								</div>
							</div>
							{role?.id !== Number(appState.userRoles.user.value) && (
								<Textarea
									className="w-full"
									rows={5}
									label="Observações"
									{...form.register('observation')}
								/>
							)}
						</div>
					</div>

					{role?.id !== Number(appState.userRoles.user.value) && (
						<FormButtons
							className="mt-4"
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							onSave={form.handleSubmit(addNewVehicle)}
							onCancel={() => {
								navigate(-1);
							}}
							saveProps={{
								disabled: form.formState.isSubmitting,
							}}
						/>
					)}
				</WhiteContainer>
				{role?.id !== Number(appState.userRoles.user.value) && (
					<div className="flex w-full flex-col gap-4 lg:w-1/4">
						<div className="sticky top-0 flex w-full flex-col gap-4">
							<Form
								form={statusForm}
								onSubmit={handleAccessControlStatus}
								className="w-auto"
							>
								<div
									className={twMerge(
										'relative mb-4 flex w-full flex-col justify-between gap-4 rounded bg-neutral-1 px-4 py-4 text-neutral-900 shadow transition-all hover:opacity-100',
										`ring-2`,
										statusForm.watch('status') === '1' && 'ring-yellow-800',
										statusForm.watch('status') === '2' && 'ring-terracota-500',
										statusForm.watch('status') === '3' && 'ring-green'
									)}
								>
									<span>
										<h1 className="font-semibold">Controle de acesso</h1>
										<hr className="text-neutral-200" />
									</span>
									<Controller
										control={statusForm.control}
										name="status"
										render={({ field }) => (
											<RadioGroup.Root
												value={String(field.value)}
												className="flex w-full flex-col  gap-[10px]"
												onValueChange={field.onChange}
											>
												{entityStatus?.map((status) => (
													<div
														className="flex items-center gap-2"
														key={status.value}
													>
														<RadioGroup.Item
															className="h-4 w-4 rounded-full bg-white shadow shadow-neutral-500 hover:bg-neutral-100"
															value={String(status.value)}
															id={String(status.value)}
														>
															<RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-full after:bg-neutral-0" />
														</RadioGroup.Item>
														<label
															htmlFor={String(status.value)}
															className="tex-neutral-600 relative inline-flex cursor-pointer items-center text-sm"
														>
															{status.label}
														</label>
													</div>
												))}
											</RadioGroup.Root>
										)}
									/>
									<Button
										type="submit"
										disabled={!statusForm.formState.isDirty}
									>
										Alterar
									</Button>
								</div>
							</Form>
						</div>
					</div>
				)}
			</div>

			{role?.id !== Number(appState.userRoles.user.value) && (
				<WhiteContainer className="mb-10 mt-4 gap-4">
					<FormHeader
						title="Empresas"
						description="Empresa proprietária do veículo"
					/>
					<CompaniesTable
						companies={vehicleToEdit ? [vehicleToEdit?.company] : []}
					/>
				</WhiteContainer>
			)}

			{role?.id === Number(appState.userRoles.user.value) && (
				<FormButtons
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					onSave={form.handleSubmit(addNewVehicle)}
					onCancel={() => {
						navigate(-1);
					}}
					saveProps={{
						disabled:
							form.formState.isSubmitting ||
							vehicleToEdit?.status ===
								Number(appState.approvalStatus.Aprovado.value),
					}}
				/>
			)}
			<Modal
				open={openModal}
				setOpen={setOpenModal}
				title="Escolha a categoria do veículo"
			>
				<div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
					{data?.data.map((category: VehicleCategory) => {
						return (
							<div
								className={twMerge(
									'relative flex flex-col items-center gap-4 rounded bg-white shadow-md ring-neutral-900 hover:ring-2',
									form.watch('categoryId') === category.id &&
										'bg-yellow-500/50  ring-2 ring-blue',
									!category.active && 'cursor-not-allowed opacity-30'
								)}
								key={category.id}
							>
								<Controller
									control={form.control}
									name="categoryId"
									render={({ field }) => {
										return (
											<>
												<div
													onClick={() => {
														category.active && field.onChange(category.id);
														form.setValue('activeFeature', '');
													}}
													className={twMerge(
														'flex w-full cursor-pointer items-center justify-center gap-4 rounded-md p-4 ',
														!category.active && 'cursor-not-allowed'
													)}
												>
													<div className="flex flex-col items-center">
														<VehicleIcon
															// @ts-expect-error
															name={oldIcons[category?.icon]}
															size={50}
														/>
														{category?.name}

														<div className="text-xs ">
															<div>
																Dimensões aproximadas:{category.height} x
																{category.length} x {category.width}
															</div>
															<div>Capacidade:{category.weight}kg</div>
														</div>
													</div>
												</div>

												{field.value === category?.id &&
													category.typeOneActive &&
													category.typeTwoActive && (
														<Controller
															key={category.id}
															control={form.control}
															name="activeFeature"
															render={({ field }) => {
																return (
																	<div
																		className={twMerge(
																			'absolute bottom-0 flex h-full w-full flex-col items-center rounded bg-white'
																		)}
																	>
																		<h1 className="mt-2">
																			Escolha a subcategoria:
																		</h1>
																		<div className="grid h-full w-full grid-cols-2 gap-2 rounded p-2">
																			<div
																				onClick={() =>
																					field.onChange('featureOne')
																				}
																				className={twMerge(
																					'flex cursor-pointer flex-col items-center justify-center rounded-md  hover:bg-yellow-500/50 ',
																					field.value === 'featureOne' &&
																						'bg-yellow-500/50 '
																				)}
																			>
																				<VehicleIcon
																					size={40}
																					name={
																						// @ts-expect-error
																						oldIcons[category.typeOneIcon]
																					}
																				/>

																				<span className="text-center text-sm">
																					{category.typeOneDescription}
																				</span>
																			</div>
																			<div
																				onClick={() =>
																					field.onChange('featureTwo')
																				}
																				className={twMerge(
																					'flex cursor-pointer flex-col items-center justify-center rounded-md hover:bg-yellow-500/50 ',
																					field.value === 'featureTwo' &&
																						' bg-yellow-500/50 '
																				)}
																			>
																				<VehicleIcon
																					size={40}
																					name={
																						// @ts-expect-error
																						oldIcons[category.typeTwoIcon]
																					}
																				/>

																				<span className="text-center text-sm">
																					{category.typeTwoDescription}
																				</span>
																			</div>
																		</div>
																	</div>
																);
															}}
														/>
													)}
											</>
										);
									}}
								/>
							</div>
						);
					})}
				</div>
				<div className="flex w-full justify-end">
					<Button
						className="mt-4 w-auto"
						onClick={() => {
							setOpenModal(false);
						}}
					>
						Salvar
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default AddVehicle;
